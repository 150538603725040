@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css");

header {
	text-align: center;
	margin: 1em auto;
}

.movie-title {
	font-size: 16px;
}

.logo {
	width: 60px;
}
